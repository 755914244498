import { Injectable } from '@angular/core';
import { UiBarChartBar } from '@big-direkt/ui/chart';
import { DiabetesBarModel } from '../models/diabetes-bar.model';
import { DiabetesRiskDiagnosisForm } from '../models/diabetes-risk-diagnosis-form.model';
import {
    DiabetesRiskDiagnosisResults,
    DiabetesRiskDiagnosisResultsBlock,
    DiabetesRiskDiagnosisResultsBlockItem,
} from '../models/diabetes-risk-diagnosis-results.model';

export const DIAGNOSIS_RESULTS: DiabetesRiskDiagnosisResults = {
    under7: {
        bmiLt30: {
            healthCheck: ['health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['prevention_services_quit_smoking', 'app_quit_smoking'],
        },
        bmiGt30: {
            healthCheck: ['health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['digital_health_application', 'prevention_services_quit_smoking', 'app_quit_smoking'],
        },
        bmiGt40: {
            healthCheck: ['health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['prevention_services_quit_smoking', 'app_quit_smoking'],
        },
    },
    from7to11: {
        bmiLt30: {
            healthCheck: ['health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['prevention_services_quit_smoking', 'app_quit_smoking'],
        },
        bmiGt30: {
            healthCheck: ['health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['digital_health_application', 'prevention_services_quit_smoking', 'app_quit_smoking'],
        },
        bmiGt40: {
            healthCheck: ['health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['prevention_services_quit_smoking', 'app_quit_smoking'],
        },
    },
    from12to14: {
        bmiLt30: {
            healthCheck: ['doctor_search', 'health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['prevention_services_quit_smoking', 'app_quit_smoking'],
        },
        bmiGt30: {
            healthCheck: ['doctor_search', 'health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['digital_health_application', 'prevention_services_quit_smoking', 'app_quit_smoking'],
        },
        bmiGt40: {
            healthCheck: ['doctor_search', 'health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['prevention_services_quit_smoking', 'app_quit_smoking'],
        },
    },
    from15to20: {
        bmiLt30: {
            healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['prevention_services_quit_smoking', 'app_quit_smoking'],
        },
        bmiGt30: {
            healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['digital_health_application', 'prevention_services_quit_smoking', 'app_quit_smoking'],
        },
        bmiGt40: {
            healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['prevention_services_quit_smoking', 'app_quit_smoking'],
        },
    },
    over20: {
        bmiLt30: {
            healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['prevention_services_quit_smoking', 'app_quit_smoking'],
        },
        bmiGt30: {
            healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['digital_health_application', 'prevention_services_quit_smoking', 'app_quit_smoking'],
        },
        bmiGt40: {
            healthCheck: ['doctor_search', 'medical_appointment_service', 'health_check_up_35', 'family_doctor_care', 'family_doctor_search'],
            preventionServices: ['bigtionaer', 'prevention_courses', 'sports_medical_check_up'],
            treatmentServices: ['prevention_services_quit_smoking', 'app_quit_smoking'],
        },
    },
};

/* eslint-disable @typescript-eslint/no-magic-numbers */
export const BAR_VALUES: DiabetesBarModel[] = [
    { percentage: 1 * 2, barClass: 'bg-redesign-success-600', label: '1% Ihr Risiko¹', percentageAmount: '1%', labelClass: 'font-bold' },
    { percentage: 2 * 2, barClass: 'bg-redesign-success-600', label: '4% Ihr Risiko¹', percentageAmount: '4%', labelClass: 'font-bold' },
    { percentage: 17 * 2, barClass: 'bg-redesign-warning-500', label: '17% Ihr Risiko¹', percentageAmount: '17%', labelClass: 'font-bold' },
    { percentage: 25 * 2 + (8 * 50) / 75, barClass: 'bg-redesign-warning-500', label: '33% Ihr Risiko¹', percentageAmount: '33%', labelClass: 'font-bold' },
    { percentage: 25 * 2 + (25 * 50) / 75, barClass: 'bg-[#f23b39]', label: '50% Ihr Risiko¹', percentageAmount: '50%', labelClass: 'font-bold' },
];

@Injectable({ providedIn: 'any' })
export class DiabetesDiagnosisService {
    // eslint-disable-next-line @typescript-eslint/max-params
    public getRiskPoints(
        age: DiabetesRiskDiagnosisForm['age']['value'],
        hasRelativesWithDiabetes: DiabetesRiskDiagnosisForm['hasRelativesWithDiabetes']['value'],
        bodyWaistSize: DiabetesRiskDiagnosisForm['bodyWaistSize']['value'],
        doesSports: DiabetesRiskDiagnosisForm['doesSports']['value'],
        eatsHighFiberFoods: DiabetesRiskDiagnosisForm['eatsHighFiberFoods']['value'],
        medicationBloodPressure: DiabetesRiskDiagnosisForm['medicationBloodPressure']['value'],
        highBloodSugarLevels: DiabetesRiskDiagnosisForm['highBloodSugarLevels']['value'],
        bmi: number,
    ): number {
        let riskPoints = 0;

        switch (age) {
            case '35to44':
                riskPoints += 1;
                break;
            case '45to54':
                riskPoints += 2;
                break;
            case '55to64':
                riskPoints += 3;
                break;
            case 'gt65':
                riskPoints += 4;
                break;
            default:
                break;
        }

        switch (hasRelativesWithDiabetes) {
            case 'distantRelationship':
                riskPoints += 3;
                break;
            case 'closeRelationship':
                riskPoints += 5;
                break;
            default:
                break;
        }

        switch (bodyWaistSize) {
            case 'lt80':
            case 'lt94':
                riskPoints += 0;
                break;
            case '80to88':
            case '94to102':
                riskPoints += 3;
                break;
            case 'gt88':
            case 'gt102':
                riskPoints += 4;
                break;
            default:
                break;
        }

        if (!doesSports) {
            riskPoints += 2;
        }

        if (!eatsHighFiberFoods) {
            riskPoints += 1;
        }

        if (medicationBloodPressure) {
            riskPoints += 2;
        }

        if (highBloodSugarLevels) {
            riskPoints += 5;
        }

        if (bmi > 25 && bmi < 30) {
            riskPoints += 1;
        } else if (bmi > 30) {
            riskPoints += 3;
        }

        return riskPoints;
    }
    /* eslint-enaable @typescript-eslint/no-magic-numbers */

    public getDiagnosisBlock(points: number, bmi: number): DiabetesRiskDiagnosisResultsBlockItem | undefined {
        const riskPoints = this.getPointsRange(points);
        const bmiRange = this.getBmiRange(bmi);

        return DIAGNOSIS_RESULTS[riskPoints][bmiRange];
    }

    public getBarValueByPoints(points: number): DiabetesBarModel {
        if (points < 7) {
            return BAR_VALUES[0];
        }
        if (points < 12) {
            return BAR_VALUES[1];
        }
        if (points < 15) {
            return BAR_VALUES[2];
        }
        if (points < 21) {
            return BAR_VALUES[3];
        }

        return BAR_VALUES[4];
    }

    public getBarByGenderReference(gender: DiabetesRiskDiagnosisForm['gender']['value'], ageGroup: DiabetesRiskDiagnosisForm['age']['value']): UiBarChartBar {
        let percentage = 0;
        let label = '';

        if (gender === 'f') {
            switch (ageGroup) {
                case 'lt35':
                    percentage = 0.47;
                    break;
                case '35to44':
                    percentage = 2.41;
                    break;
                case '45to54':
                    percentage = 5.4;
                    break;
                case '55to64':
                    percentage = 9.99;
                    break;
                case 'gt65':
                    percentage = 24.26;
                    break;
                default:
                    break;
            }

            label = `${percentage.toLocaleString('de-DE')}% durchschnittliches Risiko einer Frau selben Alters²`;
        } else {
            switch (ageGroup) {
                case 'lt35':
                    percentage = 0.17;
                    break;
                case '35to44':
                    percentage = 2.52;
                    break;
                case '45to54':
                    percentage = 7.14;
                    break;
                case '55to64':
                    percentage = 13.99;
                    break;
                case 'gt65':
                    percentage = 29.32;
                    break;
                default:
                    break;
            }

            label = `${percentage.toLocaleString('de-DE')}% durchschnittliches Risiko eines Mannes selben Alters²`;
        }

        return {
            barClass: 'bg-primary',
            label,
            percentage,
        };
    }

    public getPointsRange(points: number): keyof DiabetesRiskDiagnosisResults {
        if (points >= 7 && points <= 11) {
            return 'from7to11';
        } else if (points >= 12 && points <= 14) {
            return 'from12to14';
        } else if (points >= 15 && points <= 20) {
            return 'from15to20';
        } else if (points > 20) {
            return 'over20';
        }

        return 'under7';
    }

    private getBmiRange(bmi: number): keyof DiabetesRiskDiagnosisResultsBlock {
        if (bmi > 30 && bmi <= 40) {
            return 'bmiGt30';
        } else if (bmi > 40) {
            return 'bmiGt40';
        }

        return 'bmiLt30';
    }
}
